html,
body {
  margin: 0;
  padding: 0;
}

body {
  letter-spacing: 0.25px;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul:not([data-list]),
ol:not([data-list]) {
  list-style: none;
}

p {
  max-width: 60ch;
}

table {
  font-variant-numeric: inherit !important;
}

button {
  letter-spacing: inherit;
}

iframe {
  max-width: 100%;
}

iframe[src*="youtube"] {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.react-datepicker__triangle {
  fill: var(--chakra-colors-gray-200) !important;
  color: var(--chakra-colors-gray-200) !important;
  stroke: transparent;
}
