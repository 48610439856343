.calendar {
  border-color: var(--chakra-colors-gray-200) !important;
  font-family: inherit !important;
}

.calendar :global(.react-datepicker__month-container) {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.calendar :global(.react-datepicker__header) {
  background-color: var(--chakra-colors-gray-200);
  border-bottom: none;
}

.calendar :global(.react-datepicker__navigation-icon::before) {
  border-color: var(--chakra-colors-blue-500);
}

.calendar :global(.react-datepicker__day) {
  font-weight: bold;
}

.calendar :global(.react-datepicker__navigation) {
  top: 8px;
}

.calendar :global(.react-datepicker__day--keyboard-selected) {
  background-color: var(--chakra-colors-yellow-200);
}

.calendar :global(.react-datepicker__day-name),
.calendar :global(.react-datepicker__day),
.calendar :global(.react-datepicker__time-name) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.calendar :global(.react-datepicker__navigation-icon::before) {
  height: 5px;
  width: 5px;
}

.calendar :global(.react-datepicker__navigation--next) {
  top: 4px;
}
