.root {
  grid-area: header;
}

.link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: center;
}

.link:hover {
  text-decoration: underline;
}
