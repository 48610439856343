.card {
  background-color: transparent;
  perspective: 1000px;
  max-width: 600px;
}

.inner {
  display: grid;
  transition: transform 0.3s;
  transform-style: preserve-3d;
}

.front,
.back {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 0;
  transform: translate3d(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
}

.flipped .inner,
.back {
  transform: rotateY(180deg);
}

.index {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 10px;
}
